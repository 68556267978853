<template>
    <div class="container py-5">
        <div class="row justify-content-center">
            <div class="col-12 col-md-8 text-justify">
                <div >
                    <strong>ПОЛИТИКА ЗА ЛИЧНИ ДАННИ НА &quot;<router-link to="/">КЛИЙНЪР ГРУП</router-link>&quot; ЕООД</strong><br />
                    <br />
                    Предмет: Тази Политика за лични данни (&quot;Политика&quot;) описва как обработваме лични данни във връзка с използването на нашия интернет сайт www.cleaner.bg (&quot;Сайта&quot;), както и във връзка с предлаганите от нас на Сайта продукти и услуги, в това число почистване на домове и офиси, техническа поддръжка и др. Моля, прочетете тази Политика внимателно, така че да разберете как събираме и обработваме Вашите лични данни.<br />
                    Дефиниции: Термините &quot;лични данни&quot;, &quot;обработка&quot;, &quot;администратор&quot;, &quot;обработващ&quot;, &quot;трета държава&quot;, &quot;международна организация&quot;, &quot;субект на данни&quot;, &quot;представител&quot; и &quot;държава членка&quot; (и техните производни) използвани в тази Политика имат значение съгласно Общия регламент относно защитата на данните (ЕС) 2016/679 (&quot;ОРЗД&quot; или &quot;GDPR&quot;).<br />
                    <br />
                    <strong>1. АДМИНИСТРАТОР И КООРДИНАТИ ЗА ВРЪЗКА</strong></div>
                <div style="margin-left: 40px; ">
                    Администратор на личните данни по тази Политика и администратор на Сайта е &quot;КЛИЙНЪР ГРУП&quot; ЕООД. Ако имате някакви въпроси по отношение на тази Политика може да се свържете с нас по имейл на адрес office@cleaner.bg, по поща на посочения тук адрес за кореспонденция или по телефон на номер 087 630 5600.</div>
                <div >
                    <br />
                    <strong>2. ЛИЧНИ ДАННИ, КОИТО СЪБИРАМЕ И ИЗПОЛЗВАМЕ</strong></div>
                <div style="margin-left: 40px; ">
                    2.1. Клийнър обработва следните категории лични данни:<br />
                    &bull; данни, които ни предоставяте по време на процеса на поръчка, сключване и изпълнение на договор и по-конкретно име, фамилия, имейл, телефон, данни за фактуриране, данни за контакт и за отговорно лице, данни за достъп до помещения и др. История на Вашите поръчки, ползвани услуги, данни за контакт и договори. Данни във връзка с плащания, като напр. банкова информация, банкова сметка, използвана дебитна/кредитна карта, начин на плащане, титуляр и др.;<br />
                    &bull; информация, която ни предоставяте когато се свързвате или взаимодействате с нас чрез какъвто и да било вид комуникация по конкретни въпроси, поръчки, оплаквания, рекламации или запивания, в това число по телефон, поща, чрез форми на Сайта, по имейл, в социалните медии (например през профил във Facebook) или по друг начин и по-конкретно име, фамилия, имейл, телефонен номер, профил в социална мрежа, снимки, адрес и др. В някои случаи и след предупреждение може да правим и звукови записи на телефонните Ви разговори с нас. Когато се свързвате с нас в социалните медии, например Facebok, профилите ни там може да са публични и Вашето взаимодействие или публикуване на информация в тях може да бъде публично. Обърнете внимание, че ние не контролираме и не носим отговорност за използването на Вашия профил в социалните медии;<br />
                    &bull; информация за устройството Ви и онлайн идентификатори, като IP адрес, използвано устройство, местоположение, софтуер на устройството, данни от &quot;бисквитки&quot; (вижте нашата Политика за &quot;бисквитките&quot;);<br />
                    2.2. Обикновено събираме данните директно от Вас. В редки случаи може да получим данни за Вас от трети страни, като например от банкова институция за извършено плащане, от Ваши близки при направа на подарък или договор в полза на трето лице, от публичен регистър.<br />
                    2.3. В някои случаи предоставянето от Вас на лични данни е необходимо за сключване и изпълнение на договор (например за сключване на договор за наша услуга, за извършване на плащане по договор и т.н.). В тези случаи ако не предоставите необходимите лични данни Вие няма да може да получите услуги от нас или услугите може да не бъдат изпълнени надлежно. Например ако не ни предоставите валиден адрес и достъп няма да може да доставим поръчани материали или да извършим уговорено почистване.&nbsp;<br />
                    2.4. Ваши отговорности:<br />
                    &bull; Когато ни предоставяте данни, в това число и лични данни, Вие декларирате и гарантирате, че същите са верни и точни, че Вие имате право да ни предоставите такива данни за посочените в тази Политика цели и това не накърнява права на трети лица, в това число право на неприкосновеност, право на свобода на личността, авторско и сродно право, право на интелектуална собственост и др.;<br />
                    &bull; Сайтове на трети страни и външни препратки: За ваше удобство на Сайта може да има реклами или връзки към други сайтове или услуги, управлявани от лица, различни от нас, като например Facebook. Настоящата Политика не се отнася и ние не носим отговорност за обработката на лични данни от такива трети лица. Съветваме Ви, ако изберете да посетите или ползвате някой от тези сайтове или услуги на трети лица да прегледате техните политики за лични данни, които може да са различни от нашата Политика;<br />
                    &bull; Когато ни предоставите лични данни или се свържете с нас, вие декларирате и гарантирате, че сте навършили 18 години. Нашите услуги и процеси не са предназначени за лица под тази възраст и не събираме и обработваме умишлено данни на по-млади лица.</div>
                <div >
                    <br />
                    <strong>3. ЦЕЛИ НА ОБРАБОТКАТА (КАК ИЗПОЛЗВАМЕ ВАШИТЕ ДАННИ)</strong></div>
                <div style="margin-left: 40px; ">
                    3.1. Основната цел на събиране и обработка на Ваши лични данни е сключването и изпълнението на договор за услуги помежду ни (в това число идентифициране, включително, фактуриране, плащане, отчитане на извършена работа, доставка, комуникация, упражняване на права и задължения по договора и др.).<br />
                    3.2. Ние може да използваме Ваши лични данни също и за следните други конкретни цели:<br />
                    &bull; за общуване с Вас и за да Ви предоставяме поискана от Вас информация във връзка с нашия Сайт, начинът на използването му, предлаганите от нас стоки и услуги;<br />
                    &bull; за да Ви изпращаме административно-правна информация, като например информация за промяна на нашите Общи условия, Политики и др.;<br />
                    &bull; за да Ви изпращаме информационен бюлетини, рекламни материали и други известия по поща, имейл, кратки съобщения (SMS), изскачащи съобщения или по телефон. Това са съобщения или материали за целите на рекламата, да Ви рекламираме наши продукти или услуги, текущи или предстоящи промоции и кампании, като винаги и по всяко време ще Ви даваме право безплатно и лесно да възразите срещу получаването на такива съобщения, съответно да обработваме такива Ваши възражения и откази;<br />
                    &bull; за да спазваме или изпълняваме наши задължения, произлизащи от закон, нормативен акт, акт на изпълнителната или на съдебната власт, като например за целите на счетоводството, контрола и отчетността, одита и данъчни цели;<br />
                    &bull; за да упражняваме наши права или опазваме Ваши, наши, на трети лица или обществени законни интереси, както например откриване, разследване и предотвратяване на кражби, престъпления, измами, злоупотреба с услуги или Сайта и други закононарушения, упражняване на права по договори, по които сме страна и др. подобни;<br />
                    &bull; за статистика и анализи с цел подобряване и промени по нашите продукти, услуги, процедури и политики, по Сайта и неговите функционалности (в това число чрез събраната от Вашия уеб браузер или приложение информация, като IP адрес, операционна система, тип браузер, детайли за посещения, местонахождение и др.);<br />
                    &bull; при провеждане на специални промоционални кампании с награди, в които случаи може да има допълнителни специални правила за обработка на данни.</div>
                <div >
                    <br />
                    <strong>4. ПРАВНО ОСНОВАНИЕ НА ОБРАБОТВАНЕТО</strong></div>
                <div style="margin-left: 40px; ">
                    4.1. При поръчки, продажби и ползване на предоставяни от нас услуги, както и за тяхното надлежно изпълнение обработването на Вашите данни е необходимо за сключването и изпълнението на договор, по който Вие сте страна.<br />
                    4.2. За други цели и в отделни случаи ние може да разчитаме и на друго правно основание за обработка на Вашите данни, както следва:<br />
                    &bull; Вие сте дал съгласие за обработването - когато сте поискали да получавате рекламни съобщения или материали от нас;<br />
                    &bull; обработването е необходимо за спазването на наше законово задължение - например за счетоводната отчетност и контрол, за данъчни цели, за целите на безопасни и здравословни условия на труд и др.;<br />
                    &bull; обработването е необходимо за целите на наши или на трета страна легитимни интереси, като например: в някои случаи когато обработваме данни на служители на наши клиенти, като например отговорници по качеството, охрана, служители по безопасност на труда и др., при някои хипотези на реклама към наши клиенти; при поръчка на подаръчен ваучер от Ваши близки; за разследване и предотвратяване на кражби, престъпления, измами, злоупотреба с услуги или със Сайта; за подобряване и промени по нашите продукти, услуги, процедури и политики, по Сайта и неговите функционалности.</div>
                <div >
                    <br />
                    <strong>5. ПОЛУЧАТЕЛИ НА ЛИЧНИ ДАННИ</strong></div>
                <div style="margin-left: 40px; ">
                    Вашите лични данни могат да бъдат разкривани на следните категории получатели:<br />
                    &bull; наши доставчици на услуги и подизпълнители, като например: за изпълнение на договора ни с Вас на обслужващите ни подизпълнители, куриерски и транспортни фирми, счетоводна и правна компании; на дружества, от които сме лицензирали специализирани програмни продукти, като например счетоводен, складов, комуникационен или др. софтуер; на дружества, които ни доставят услуги и оказват техническа поддръжка във връзка със Сайта и с комуникациите, както и на др. подобни доставчици на услуги;<br />
                    &bull; доставчици на платежни услуги, с цел идентифициране и обработване на плащания;<br />
                    &bull; лица на които сме длъжни да разкрием данни съгласно приложимите закони, актове на изпълнителната или на съдебната власт, или при съдебни процедури, или във връзка с разследване на незаконна дейност, или при съмнения за такава, или при законно искане от страна на държавни, правителствени или регулаторни органи;<br />
                    &bull; на трети лица, когато имаме обосновано предположение, че такова разкриване е необходимо, за да се предотврати смърт или вреда, или финансови загуби или във връзка с откриване, разследване и предотвратяване на кражба, престъпление, измама, злоупотреба с услуги или със Сайта, други закононарушения, включително нарушаване на наши права и договори и на правата на трети лица, като например авторски права, права върху интелектуална собственост или права за поверителност;<br />
                    &bull; на свързани с нас лица, с които сме под общ контрол за посочените в тази Политика цели;<br />
                    &bull; на трети лица в случай на реорганизация, сливане, вливане, продажба, съвместно предприятие, прехвърляне или друго разпореждане с цялата или с част от нашата стопанска дейност;<br />
                    &bull; публично - когато се свързвате с нас в социалните медии, например Facebook, профилите ни там може да са публични и Вашето взаимодействие или публикуване на информация в тях може да бъде публично. Това зависи и от настройките на Вашия профил, като ние не контролираме и не носим отговорност за използването на Вашия профил.</div>
                <div >
                    <br />
                    <strong>6. ПРЕДАВАНЕ В ТРЕТА ДЪРЖАВА</strong></div>
                <div style="margin-left: 40px; ">
                    6.1. Понякога при предоставяне на нашите услуги съгласно тази Политика може да се извърши прехвърляне на лични данни към юрисдикции извън Европейския съюз, които имат различно законодателство за защита на личните данни. В такива случаи ние ще прехвърля лични данни в трета държава само въз основа на: а) решение на Европейската комисия относно адекватно ниво на защита на тази държава (чл. 46 от GDPR), включително въз основа на рамката Privacy Shield или друг подобен одобрен механизъм; или б) подходящи гаранции, предвидени в чл. 46 от GDPR, включително като разчитаме на Стандартните договорни клаузи за лица обработващи данни съгласно Приложение към Решение на Европейската комисия от 05 февруари 2010 г. (налично към датата на изготвяне на това споразумение тук: https://eur-lex.europa.eu/legal-content/BG/TXT/HTML/?uri=CELEX:32010D0087&amp;from=EN), както бъдат изменяни или заменяни във времето от Европейската комисия или на подходящи гаранции при конкретно разрешение на компетентен надзорен орган; или в) одобрени от компетентен надзорен орган задължителни фирмени правила (чл. 47 от GDPR).<br />
                    6.2. В други случаи може да разчитаме и изискаме Вашето съгласие за подобно прехвърляне в трети държави, в които случаи подробно ще Ви уведомим за трансфера и за предприетите във връзка със същия технически и организационни мерки за защита на лични данни.</div>
                <div >
                    <br />
                    <strong>7. ВАШИТЕ ПРАВА</strong></div>
                <div style="margin-left: 40px; ">
                    Субектите на лични данни имат следните права:<br />
                    7.1. Право да оттеглите съгласието си. Когато обработваме личните Ви данни на основание на Вашето съгласие (някои случаи на директен маркетинг), Вие имате право да оттеглите същото по всяко време. Това няма да засегне законосъобразността на обработването, преди съгласието да бъде оттеглено. Вие може да упражните това право без допълнителни разходи за Вас по един от следните начини:<br />
                    &bull; по всяко време ни изпратите имейл на Office@cleaner.bg с ясно изявление, че оттегляте съгласието си;<br />
                    &bull; като ни се обадите по телефона на 087 630 5600 в работно време;<br />
                    &bull; по пощата на адреса ни на управление, посочен в тази Политика;<br />
                    &bull; като използвате формата за контакт на Сайта.<br />
                    В случай, че оттеглите съгласието си за обработване на личните Ви данни ние ще заличим същите от нашите системи до степента предвидена в закона и в разумен срок.<br />
                    7.2.&nbsp; Право на достъп до данните - по всяко време може да поискате информация за личните Ви данни, които съхраняваме. В някои случаи това ще бъдат единствено личните данни записани от Вас във Вашия профил.<br />
                    7.3. Право на коригиране - да поискате коригиране на Вашите лични данни, ако те са неточни. Ако имате профил на Сайта може да редактирате свои лични данни и от него.<br />
                    7.4. Право на изтриване на данните - &quot;правото да бъдете забравен&quot;. Следва да имате предвид, че това не е абсолютно право и се прилага в съответствие с предвиденото в закона. Например имате право да поискате изтриване на Вашите лични данни ако същите повече не са необходими за целите, за които са били събрани или обработвани по друг начин, но при положение, че за нас не съществува правно задължение, което изисква по-нататъшно обработване (например част от Вашите данни продължават да се обработват за целите на счетоводната отчетност и контрол съгласно изискванията на счетоводното законодателство). Във всички случаи ние ще уважим всяко основателно и законосъобразно искане за изтриване на лични данни.<br />
                    7.5. Право на ограничаване на обработването съгласно приложимия закон.<br />
                    7.6. Право на преносимост на данните - в определените в закона хипотези може да поискате да получите или да прехвърлим на трета страна копие от Вашите данни в структуриран, широко използван формат (това засяга само автоматично обработваните предоставени от Вас данни обработвани на основание на Вашето съгласие или на сключен с Вас договор).<br />
                    7.7. Право на възражение. В частност това включва правото Ви да възразите по всяко време на обработването на Вашите лични данни, което обработване се базира на:<br />
                    &bull; изпълнението на задача от обществен интерес или упражняването на официални правомощия от нас; или<br />
                    &bull; нашия или на трета страна легитимни интереси;<br />
                    В тези случаи ще прекратим обработването освен ако се докаже, че съществуват убедителни законови основания за обработването, които имат предимство пред интересите, правата и свободите на субекта на данни, или за установяването, упражняването или защитата на правни претенции; или<br />
                    &bull; за целите на директния маркетинг - може да възразите по всяко време като следвате указанията в съответното маркетингово съобщение или като ни пишете на Office@cleaner.bg.<br />
                    7.8. В случай, че сте установен в Европейския съюз Вие също имате право да подадете жалба до надзорен орган и по-специално до надзорния орган в държавата членка на обичайното Ви местопребиваване, на мястото Ви на работа или на мястото на предполагаемото нарушение. Надзорен орган по защита на лични данни в България е Комисията за защита на личните данни с интернет страница на адрес: www.cpdp.bg.<br />
                    7.9. Може да упражните гореописаните права в съответствие с приложимия закон и по-специално с предвиденото в GDPR. За да упражните Ваше право спрямо нас може да се свържете по всяко време с нас на office@cleaner.bg. В тези случаи може да се наложи да потвърдим Вашата самоличност или да се свържем с Вас във връзка с искането Ви.</div>
                <div >
                    <br />
                    <strong>8. ПЕРИОД НА СЪХРАНЕНИЕ</strong></div>
                <div style="margin-left: 40px; ">
                    8.1. Ще съхраняваме личните данни доколкото е необходимо за съответните цели на обработката и за изпълнение на наши задължени установени в закон.<br />
                    8.2. В частност ще съхраняваме лични данни:<br />
                    &bull; данните за целите на сключването и изпълнението на договор - в общия случай в срок от 5 години от датата на изпълнение на съответния договор;<br />
                    &bull; данните за целите на счетоводната отчетност и контрол - в съответствие с нормативно установените счетоводни и контролни правила. Данните обработвани във връзка с други нормативно установени задължения ще съхраняваме до отпадане на съответното задължение;<br />
                    &bull; данните за целите на директния маркетинг - докато оттеглите Вашето съгласие (ако е приложимо) или докато възразите, или при изтичане на 1 година от датата на последното изпратено от нас до Вас съобщение, в което Ви напомняме за правото на отказ.<br />
                    &bull; може да преустановите общуването с нас в социалните медии по всяко време и според инструментите на съответната социална медия, например ако сте харесал нашата страница във Facebook може да оттеглите такова харесване (unlike) и т.н.<br />
                    8.3. Лични данни, които обработваме за други цели ще бъдат обработени и съхранявани, както е необходимо и в съответствие законите за защита на данни и приложимите стандарти.<br />
                    8.4. За избягване на съмнение ние може да генерираме и да съхраняваме обобщени статистически отчети и материали, които не съдържат лични данни и от които конкретно лице не може да бъде индивидуализирано. Настоящата Политика не се прилага спрямо тях, тъй като същите не съдържат лични данни.</div>
                <div >
                    <br />
                    <strong>9. АВТОМАТИЗИРАНА ОБРАБОТКА</strong></div>
                <div style="margin-left: 40px; ">
                    Ние няма да базираме наши решения единствено и само на софтуер за автоматична обработка на данни и винаги ще използваме и човешки фактор. Ако смятате, че определен резултат от автоматизирана обработка е неправилен, можете да поискате обяснение от нас. Може също да се противопоставите на всяко решение, което ви засяга съществено и което е взето единствено от компютър, софтуер или друг автоматизиран процес, ако има такова решение.</div>
                <div >
                    <br />
                    <strong>10. ПРОМЕНИ В НАСТОЯЩАТА ПОЛИТИКА</strong></div>
                <div style="margin-left: 40px; ">
                    10.1. Може да правим промени в тази Политика за лични данни, които промени ще публикуваме на Сайта и за които може специално да Ви уведомим по имейл или друг начин. В случай, че обработваме личните Ви данни на основание на дадено от Вас съгласие и промените засягат обработването във връзка със същото, ще Ви уведомим за промените и ще поискаме съгласието Ви за тях.</div>
            </div>
        </div>
        
    </div>
</template>